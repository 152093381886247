import {createAsyncThunk} from "@reduxjs/toolkit";
import {useHttp} from "../../hooks/http.hook";

import { projectScripts } from "../../utils/updateScripts";


export const fetchProjectInfo = createAsyncThunk(
    'projectInfo/fetchProjectInfo',
    async (_, { getState }) => {
        const {request} = useHttp();


        if (getState().projectInfo.loadingStatus === 'loaded') {
            return getState().projectInfo.items;
        }

        const {json} = await request(`api/getProject/${window.env.PROJECT_ID}`, 'GET');

            projectScripts(json.data.scripts)

        return await new Promise((resolve) => resolve(json));
    }
);

export const extraReducers = () => {
    return (builder) => {
        builder
            .addCase(fetchProjectInfo.pending, state => {
                state.loadingStatus = 'loading'
            })

            .addCase(fetchProjectInfo.fulfilled, (state, action) => {
                state.loadingStatus = 'loaded';
                state.items = action.payload.data;
            })

            .addCase(fetchProjectInfo.rejected, state => {
                state.loadingStatus = 'error';
            })
            .addDefaultCase(() => {
            })
    }
}


