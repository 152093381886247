export const useHttp = () => {
    const request = (async (url,
                            method = 'GET',
                            body = null,
                            query = null,
                            headers = {
                                'Content-Type': 'application/json',
                                'Authorization': 'Bearer ' + localStorage.getItem('access_token')
                            }) => {
        let data, response = null;

        try {
            if (query) {
                url += `?${new URLSearchParams(query)}`
            }

            let init = {
                method,
                headers
            }

            if (body) {
                init.body = JSON.stringify(body)
            }

            response = await fetch(`${window.env.API_URL}/${url}`, init);

            if (!response.ok) {
                console.error('error')
                return window.location = '/error-page';
            }

            data = await response.json();
        } catch (e) {
            // do nothing
        }

        return {json: data, response: response};
    });

    return {
        request,

    }
}

